<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.query.from_salary ? $router.push({ name: 'detachments' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'detachment'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{$route.params.id ? $t('salary.detachment.title_edit') :  $t('salary.detachment.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.query.from_salary ? $router.push({ name: 'detachments' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'detachment'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract-multiple v-if="!$route.params.id && !$route.params.contract_id" :contract="detachment" :validator="$v.detachment"/>
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.detachment.title') }}</div>
				</div>
				<div class="form">
					
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.detachment.document_number') }}*</label>
                            <div v-if="$v.detachment.document_number.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.document_number.$error}">
							<div class="icon-left"><icon-contract /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.detachment.document_number-ph')" v-model="detachment.document_number">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.detachment.document_date') }}*</label>
                            <div v-if="$v.detachment.document_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.document_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(detachment.document_date).length" @click.stop="detachment.document_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="detachment.document_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(detachment.document_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.detachment.document_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.start_date') }}*</label>
							<div v-if="$v.detachment.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(detachment.start_date).length" @click.stop="detachment.start_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="detachment.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(detachment.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.final_date') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(detachment.final_date).length" @click.stop="detachment.final_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="detachment.final_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(detachment.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.detachment.company') }}*</label>
                            <div v-if="$v.detachment.company.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.company.$error}">
							<div class="icon-left"><icon-building /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.detachment.company-ph')" v-model="detachment.company">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.detachment.cui') }}*</label>
                            <div v-if="$v.detachment.cui.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.cui.$error}">
							<div class="icon-left"><icon-cui /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.detachment.cui-ph')" v-model="detachment.cui">
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.detachment.country') }}*</label>
							<div v-if="$v.detachment.country.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.detachment.country.$error}">
							<div class="icon-left"><icon-globe /></div>
							<div class="icon-right" v-if="detachment.country != ''" @click.stop="detachment.country = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="detachment.country"
								v-bind:class="{populate: detachment.country != ''}"
								class="select-tags-tbf"
								:options="optionsCountries"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.detachment.country-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('salary.detachment.pay_salary_rights') }}*</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="detachment.pay_salary_rights = 0" v-bind:class="{active: detachment.pay_salary_rights === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="detachment.pay_salary_rights = 1" v-bind:class="{active: detachment.pay_salary_rights === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.observations') }}</label>
						</div>
						<div class="input-box bg-white">
							<textarea rows="3" :placeholder="$t('salary.vacation.observations-ph')" v-model="detachment.observations"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.query.from_salary ? $router.push({ name: 'detachments' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'detachment'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
            <button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-detachments-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContractMultiple from  './ChooseContractMultiple'
	import IconContract from '../../../../Icons/Contract'
	import IconBuilding from '../../../../Icons/Building'
	import IconCui from '../../../../Icons/Cui'
	import IconGlobe from '../../../../Icons/Globe'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderDetachmentsCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContractMultiple,
			IconContract,
			IconBuilding,
			IconCui,
			IconGlobe,
			IconCalendar,
			IconClose,
			IconArrowBack,
			LoaderDetachmentsCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
                optionsCountries: [],
                detachment: {
					contractSelected: '',
					document_number: '',
                    document_date: {},
					start_date: {},
					final_date: {},
                    company: '',
                    cui: '',
                    country: '',
                    pay_salary_rights: 0,
					observations: ''
                }
			}
		},
        validations: {
            detachment: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
                document_number: { required },
                document_date: { required },
				start_date: { required },
                company: { required },
                cui: { required },
                country: { required }
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getCountries();

            if(this.$route.params.id){
				await this.getDetachmentData()
			}
		},
        methods: {
            async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name:el[1]}
					})
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},

            getDetachmentData() {
                axios.get(`/contract-detachments/${this.$route.params.id}`).then(({data}) => {
                    this.detachment.document_number = data.data.document_number
                    if(data.data.document_date){
						this.detachment.document_date = {
							selectedDate: data.data.document_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.active_from){
						this.detachment.start_date = {
							selectedDate: data.data.active_from,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.active_to){
						this.detachment.final_date = {
							selectedDate: data.data.active_to,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    this.detachment.company = data.data.company
                    this.detachment.cui = data.data.cui
                    this.detachment.country = data.data.country ? this.optionsCountries.find(el => el.name == data.data.country) : ''
                    this.detachment.pay_salary_rights = data.data.pay_salary_rights
					this.detachment.observations = data.data.observations
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },
			changeEndDate() {
				if(this.detachment.start_date.selectedDate > this.detachment.final_date.selectedDate) {
					this.detachment.final_date = {}
				}
				this.calendarConfigsEndData.limits.min = this.detachment.start_date.selectedDate
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.document_date = Object.keys(this.detachment.document_date).length ? this.detachment.document_date.selectedDate : ''
					objData.active_from = Object.keys(this.detachment.start_date).length ? this.detachment.start_date.selectedDate : ''
					objData.active_to = Object.keys(this.detachment.final_date).length ? this.detachment.final_date.selectedDate : ''
					objData.document_number = this.detachment.document_number
					objData.company = this.detachment.company
                    objData.cui = this.detachment.cui
                    objData.country = this.detachment.country.name
                    objData.pay_salary_rights = this.detachment.pay_salary_rights
					objData.observations = this.detachment.observations
					if(this.$route.params.contract_id) {
						if(type == 'create'){
							this.createDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}else if(type == 'update'){
							this.updateDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}
					} else {
						var selectedContracts = this.detachment.contractSelected.length ? this.detachment.contractSelected.map(function(a) {return a.id;}) : ''
						if(selectedContracts.length) { objData.contract_ids = selectedContracts }
						this.createSalaryDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createSalaryDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$auth.user().instance.id}/contract-detachments/store/multiple`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'detachments' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			createDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$route.params.contract_id}/contract-detachments/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'detachments' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'detachment'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
            updateDetachment(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
                axios.patch(`/contract-detachments/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'detachments' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'detachment'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
            }
        }
	}
</script>